<template>
  <el-dialog
    :title="dataForm.id ? '编辑' : '新增' + '餐品类别'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form :model="dataForm" ref="dataForm" label-width="80px">
      <div class="tablePart">
        <el-form-item label="餐品类别名" prop="name">
          <el-input v-model="dataForm.name" clearable />
        </el-form-item>
        <el-form-item label="是否使用" prop="isUse">
          <el-radio-group v-model="dataForm.isUse">
            <el-radio :label="1">使用中</el-radio>
            <el-radio :label="0">禁用中</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序号" prop="sortNum">
          <el-input-number v-model="dataForm.sortNum" :min="0" />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      visible: false,
      dataForm: {
        id: null,
        name: null,
        isUse: 1,
        sortNum: 0,
      },
    };
  },
  methods: {
    init(id) {
      this.$init({
        before: () => {
          this.dataForm.id = id;
        },
        url: id ? `/cm/shoppingrecord/findDetail/${this.dataForm.id}` : null,
        after: (data) => {
          if (data?.code === 0) {
            this.dataForm = { ...data.shoppingDetail };
          }
        },
      });
    },
    dataFormSubmit() {
      this.visible = false;
      // this.$submit({
      //
      // })
    },
  },
};
</script>
